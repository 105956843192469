'use client'

import { useEffect, useState } from 'react'
import { cohesion } from '@redventures/cohesion-utils-react'

export const GetFuseLease = () => {
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>(undefined)

  useEffect(() => {
    // First check that leases are received
    cohesion('fuse:leasesReceived', function (leases) {
      setPhoneNumber(leases[0].dnis)
    })
  }, [])

  return phoneNumber
}
