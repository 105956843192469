import React from "react"
import HeroStarRating from './HeroStarRating'
import ValuePropStarRating from '@/components/design-system/rating/ValuePropStarRating'
interface StarRatingBaseProps {
  variant?: 'hero' | 'value-props';
  rating: number;
}


type StarRatingVariant = StarRatingBaseProps;

const VARIANT_COMPONENTS = {
  'hero': HeroStarRating,
  'value-props': ValuePropStarRating
} as const;

const StarRating: React.FC<StarRatingVariant> = ({ variant = 'hero', rating }) => {
  const Component = VARIANT_COMPONENTS[variant];
  return <Component rating={rating} />;
}

export default StarRating
