import React from "react"
import CustomIcon, { IconKeys } from '@/components/design-system/CustomIcon'
import { StarRatingProps } from "@/components/design-system/rating/interface"
import { useMonarch } from '@redventures/cohesion-utils-react'

export interface GoogleReviews {
  value: {
    data: {
      rating: number
      totalReviews: string
      linkText: string
      url: string
    }
  }
}

interface Rules {
  googleReviews?: GoogleReviews
}


const ValuePropStarRating: React.FC<StarRatingProps> = ({ rating }) => {
  const { rules }: { rules: Rules } = useMonarch()
  const ratingValue = rules?.googleReviews?.value?.data?.rating || rating

  const starsArray: string[] = Array.from({ length: 5 }, (_, index) => {
    const starValue = index + 1 // Stars are 1-indexed (1 to 5)
    if (ratingValue >= starValue) {
      return "Full"
    } else if (ratingValue > starValue - 1) {
      return "Half"
    } else {
      return "Empty"
    }
  })

  return (
    <div className="u-text-white u-flex u-flex-col t-body-sm u-justify-start u-items-start">
      <div className="u-flex  u-gap-2">
        <div
          className="u-text-white u-font-700 u-flex u-gap-x-1 u-flex-wrap md:u-flex-nowrap u-w-6/12 md:u-w-8/12">
          <span>Rated</span>{' '}<span>{ratingValue}/5</span>
        </div>
        <div className="u-h-5 u-flex u-pr-2 u-items-center u-justify-center">
          {starsArray.map((element, index) => (
            <CustomIcon
              className="u-w-4 u-p-px"
              key={index}
              icon={`star${element}` as IconKeys}
              name={`star${element}`}
              additionalClasses="c-star"
              aria-hidden="true"
              data-cy={`star-${index}`}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default ValuePropStarRating
